.footer {
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}
.footer-copyright {
    text-align: center !important;
}

.footer-body {
    z-index: 1;
    text-align: start !important;
}

@media (max-width: 767px) {
    .footer-copywright {
        text-align: center !important;
    }

    .footer-body {
        text-align: center !important;
    }
}

.footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}
.footer-icon {
    display: inline-block !important;
    padding-left: 15px;
    padding-right: 15px;
    color: #6c757d !important;
}