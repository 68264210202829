.home-about-body {
    font-size: 1.3em !important;
    text-align: left;
}

.carousel-item {
    position: unset !important;
}

.carousel-caption {
    position: inherit !important;
}

.accordion-button {
    color: #ffffff !important;
    background-color: #111111 !important;
    box-shadow: inset 0 -1px 0 rgb(1, 1, 0) !important;
    border-radius: 25px;
}

@media screen and (max-width: 800px) {
    .navbar-dropdown {
        text-align: center !important;
    }
}