.intro {
    display: flex;
    justify-content: center;
    height: 70vh;
}

.intro-picture {
    width: 300px;
}

@media screen and (max-width: 431px) {
    .intro-text {
        text-align: center !important;
    }
    .intro-picture {
        max-width: 200px;
    }
}